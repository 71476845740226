import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Slick_Slider = () => {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div className="slider-container ps-3">
                <Slider {...settings}>
                    <div className="card card_style pt-4">
                        <img src="asset/img/d1.jpg" className="card_img_style" alt="..." />
                        <div className="card-body">
                            <p className="card-text">
                                <ol><li>Go to the <b>Teen Patti Master</b> website on your Android phone and tap the "Download App" button.</li></ol></p>
                        </div>
                    </div>
                    <div className="card card_style pt-4">
                        <img src="asset/img/d2.jpg" className="card_img_style" alt="..." />
                        <div className="card-body pb-0">
                            <p className="card-text"><ol start="2"><li>After tapping "Download App," you may get a standard Android warning. Just tap "Download Anyway" to continue; the app is safe.</li></ol></p>
                        </div>
                    </div>
                    <div className="card card_style pt-4">
                        <img src="asset/img/d3.jpg" className="card_img_style" alt="..." />
                        <div className="card-body">
                            <p className="card-text"><ol start="3"><li>Now open the APK file from the file manager to start the installation process.</li></ol></p>
                        </div>
                    </div>
                    <div className="card card_style pt-4">
                        <img src="asset/img/d4.jpg" className="card_img_style" alt="..." />
                        <div className="card-body">
                            <p className="card-text"><ol start="4"><li>Tap the “Install” button for installing the app on your mobile device.</li></ol></p>
                        </div>
                    </div>
                    <div className="card card_style pt-4">
                        <img src="asset/img/d5.jpg" className="card_img_style" alt="..." />
                        <div className="card-body">
                            <p className="card-text"><ol start="5"><li>Once the Teen Patti Master app is successfully installed in your mobile, tap the “open” button.</li></ol></p>
                        </div>
                    </div>
                    <div className="card card_style pt-4">
                        <img src="asset/img/d6.jpg" className="card_img_style" alt="..." />
                        <div className="card-body">
                            <p className="card-text"><ol start="6"><li>You're all set! Start playing Teen Patti Master games and earn real cash.</li></ol></p>
                        </div>
                    </div>
                </Slider>
            </div>
        </>
    )
}