import React from "react";

export const FAQs = () => {
    return (
        <>
            <section className="mb-5">
                <div className="container mt-3">
                    <p className="fw-bold fs-3 pt-4 pb-2 mb-4 text-center underline_black">FAQs</p>
                    <div className="accordion mt-5" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    What is Teen Patti Master?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Teen Patti Master is an online mobile card game app that lets you play the popular Indian card game, Teen Patti. You can enjoy this traditional game with friends and players from all over the world.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    How to download Teen Patti Master?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <ul>
                                        <li>First Click on The Download Button given above.</li>
                                        <li>Then click on OK to start downloading the app.</li>
                                        <li>Install Teen Patti Master APK on the Android Device.</li>
                                        <li>Open the app and Click on Play as Guest.</li>
                                        <li>Click on the User Profile And connect your 10-digit mobile number using OTP.</li>
                                        <li>After That, You Can Get Rs.2100 Sign Up Bonus.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header ">
                                <button className="accordion-button fs-5 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    How do I play Teenpatti Master?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Playing Teen Patti Master is a breeze! Simply download the app, sign in, and start a new game. Place your bets, receive your cards, and aim for the best card combination to outsmart your opponents.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                    Is Teen Patti Master free to play?
                                </button>
                            </h2>
                            <div id="collapsefour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Yes, Teen Patti Master is free to download and play. However, there are in-app purchases available for those who wish to buy extra chips and other game features.Yes, Teen Patti Master is free to download and play. However, there are in-app purchases available for those who wish to buy extra chips and other game features.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                                    Can I play Teen Patti Master Game Offline ?
                                </button>
                            </h2>
                            <div id="collapsefive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    No, Teen Patti Master is an online and multi-user game. You can play this game with your friends anytime, anywhere.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                    Can I play with friends?
                                </button>
                            </h2>
                            <div id="collapsesix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Absolutely! Teen Patti Master allows you to invite and play with friends. Create a private table, share the code, and enjoy a friendly game with your buddies
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                    Can I play Teen Patti Master on my PC?
                                </button>
                            </h2>
                            <div id="collapse7" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Teen Patti Master is designed for mobile devices, but you can use an Android emulator on your PC to play it.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                    How Many Games in Master Teen Patti App?
                                </button>
                            </h2>
                            <div id="collapse8" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    In Teen Patti Master gives more than 25 games, and all games is very lightweight and awesome.<br /><br />

                                    The names of those games are as follows:<br /><br />

                                    Teen Patti Andar Bahar Car Roulette Fantasy Cricket
                                    Jhandi Munda Dragon v/s Tiger Slots 7 Up Down
                                    Point Rummy Crash Explorer Slots King v/s Queen
                                    Teen Patti War Baccarat AB AK47 Teen Patti Muflis Teen Patti
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                    How do I win chips?
                                </button>
                            </h2>
                            <div id="collapse9" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Winning chips in Teen Patti Master is all about strategy. Master the art of bluffing, understand card combinations, and play wisely to accumulate chips. Keep practicing, and you’ll be on your way to becoming a Teen Patti Master!
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                    How do I ensure a fair gaming environment?
                                </button>
                            </h2>
                            <div id="collapse10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Teen Patti Master employs advanced algorithms to guarantee fair play. Our team is dedicated to maintaining a secure and enjoyable gaming environment for all users.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                    Is Teen Patti Master secure?
                                </button>
                            </h2>
                            <div id="collapse10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Yes, Teen Patti Master uses advanced security measures to keep your personal information and transactions safe.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                    Can I chat with other players in Teen Patti Master?
                                </button>
                            </h2>
                            <div id="collapse10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Yes, Teen Patti Master has a chat feature that lets you talk to other players at your table for a more social experience.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                    How often is Teen Patti Master updated?s
                                </button>
                            </h2>
                            <div id="collapse10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    The developers regularly update the app with new features, game modes, and improvements based on player feedback to make the game better.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                    What is Teen Patti Speed?
                                </button>
                            </h2>
                            <div id="collapse10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Teen Patti Speed is a variant of Teen Patti Master designed for players who prefer a faster gameplay experience. It retains the core elements of Teen Patti but is played at a quicker pace.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                    How is Rummy Master different from Teen Patti Master?
                                </button>
                            </h2>
                            <div id="collapse10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Rummy Master is another popular card game app which is based on the game of Rummy, whereas Teen Patti Master is based on Teen Patti. Both games require strategy and skill but have different rules and gameplay styles.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                    What if I encounter issues in Teen Patti Master?
                                </button>
                            </h2>
                            <div id="collapse10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    If you have any problems, you can contact customer support through the app’s help section.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                    How can I improve my Teen Patti skills?
                                </button>
                            </h2>
                            <div id="collapse10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Practice is key! Engage in games regularly, learn from your experiences, and observe different playing styles. Additionally, check out our in-app tips and tutorials for valuable insights.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                    How can I contact support if I have issues?
                                </button>
                            </h2>
                            <div id="collapse10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    If you encounter any issues or have questions, our support team is here to help. Navigate to the “Help” section in the app or contact us through our website for prompt assistance.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                    Are these games purely based on luck?
                                </button>
                            </h2>
                            <div id="collapse10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    While luck plays a significant role in Teen Patti and Slots Meta, Rummy Master involves a higher degree of skill and strategy.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                    Is it safe to make in-app purchases in these games?
                                </button>
                            </h2>
                            <div id="collapse10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Yes, these games are developed with user security in mind. In-app purchases are conducted through secure payment systems to ensure the safety of your financial information.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                    Is there customer support available for Teen Patti Master and related games?
                                </button>
                            </h2>
                            <div id="collapse10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Yes, all these games have dedicated customer support that can be reached via the apps if you have any questions or issues.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                    Do I need an internet connection to play these games?
                                </button>
                            </h2>
                            <div id="collapse10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    An internet connection is required to play Teen Patti Master, Teen Patti Speed, Rummy Master, and Slots Meta as they are online multiplayer games.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                    Is Teen Patti Master a Genuien  App ?
                                </button>
                            </h2>
                            <div id="collapse10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Yes, Brother this is 100% genunie app. 100% Customer sports and the best game for real players.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fs-5 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                    Can I win real Money With Teen Patti Master APK ?
                                </button>
                            </h2>
                            <div id="collapse10" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Yes, Guys, you can win real money every day by playing online games. and also you can earn real cash by sharing this app with other friend.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}