import React from "react";

export const Feature = () => {
    return (
        <>
            <section className="" id="banner">
                <div>
                    <img src="asset/img/f1.jpg" className="d-block w-100 h_500" alt="..." />
                    <div className="container">
                        <p className="fs-4 mt-4">Here’s a simple look at the features of the <b>'Teen Patti Master'</b> app to help you understand what the game offers.</p>
                        <p className="fs-4 fw-bold">Let's Check out this great fetures:</p>
                        <ul className="fs-5">
                            <li>Language Options(Like: Gujarati, Hindi, etc...).</li>
                            <li>welcome Bonus.</li>
                            <li>Super Cash Back offer.</li>
                            <li>Daily Rewards.</li>
                            <li>VIP Gift.</li>
                            <li>Free Cash.</li>
                            <li>Gullak Bonus.</li>
                            <li>Scratch Card.</li>
                            <li>Multiple Game Modes.</li>
                            <li>High commission.</li>
                            <li>Security and Fair Play.</li>
                            <li>24/7 Customer Support.</li>

                        </ul>
                        <ul>
                            <li className="fs-4 fw-bold">Welcome Bonus:</li>
                            <p className="fs-5 m-0 p-0">1. Get a Special bonus when you first join the game.</p>
                            <p className="fs-5 m-0 p-0">2. Start your gaming experience with extra funds to boost your play.</p>

                            <li className="fs-4 fw-bold mt-3">Super Cash Back Offer:</li>
                            <p className="fs-5 m-0 p-0">1. Add ₹500 to your account and get ₹1000 back instantly.</p>
                            <p className="fs-5 m-0 p-0">2. Maximize your fun with double the amount you deposit.</p>

                            <li className="fs-4 fw-bold mt-3">Daily Rewards:</li>
                            <p className="fs-5 m-0 p-0">1. Receive daily rewards by spinning the wheel or completing tasks.</p>
                            <p className="fs-5 m-0 p-0">2. Keep coming back each day for new chances to win.</p>

                            <li className="fs-4 fw-bold mt-3">VIP Gift:</li>
                            <p className="fs-5 m-0 p-0">1. Receive special rewards as part of the VIP program for loyal players.</p>
                            <p className="fs-5 m-0 p-0">2. Enjoy daily gifts up to ₹5, weekly gifts up to ₹10, and monthly rewards up to ₹100.</p>

                            <li className="fs-4 fw-bold mt-3">Multiple Game Modes:</li>
                            <p className="fs-5 m-0 p-0">1. Choose from various game modes to suit your style, from classic to modern variations.</p>
                            <p className="fs-5 m-0 p-0">2. Enjoy diverse gameplay options that keep the experience fresh and exciting.</p>

                            <li className="fs-4 fw-bold mt-3">Free Cash:</li>
                            <p className="fs-5 m-0 p-0">1. Invite new friends to join the game and earn cash rewards between ₹0.1 to ₹20.</p>
                            <p className="fs-5 m-0 p-0">2. Benefit from bonus cash when your referrals sign up or deposit money.</p>

                            <li className="fs-4 fw-bold mt-3">Scratch Card:</li>
                            <p className="fs-5 m-0 p-0">1. Top up your account and scratch 30 cards for a chance to find extra money.</p>
                            <p className="fs-5 m-0 p-0">2. Enjoy the excitement of revealing hidden rewards.</p>

                            <li className="fs-4 fw-bold mt-3">Gullak Bonus:</li>
                            <p className="fs-5 m-0 p-0">1. Save bonus cash in your Gullak as you play and watch it grow.</p>
                            <p className="fs-5 m-0 p-0">2. Open your Gullak when it’s full to collect the accumulated bonus.</p>

                            <li className="fs-4 fw-bold mt-3">24/7 Customer Support:</li>
                            <p className="fs-5 m-0 p-0">1. Get assistance anytime with round-the-clock customer support.</p>
                            <p className="fs-5 m-0 p-0">2. Receive help and resolve issues quickly with available support agents.</p>

                        </ul>
                    </div>
                </div>
            </section>
            <section className="container mt-5 mb-5">
                <p className="fw-bold fs-3 text-center underline_black">Best Promotion Methods</p>
                <div className="row mt-5">
                    <div className="col-md-5 col-sm-12">
                        <div id="carouselExampleIndicators" class="carousel slide"
                            data-bs-ride="carousel" data-bs-interval="1500">
                            <div class="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            </div>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img src="asset/img/promotion1.jpg" class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src="asset/img/promotion2.jpg" class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src="asset/img/promotion3.jpg" class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src="asset/img/promotion4.jpg" class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src="asset/img/promotion5.jpg" class="d-block w-100" alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 col-sm-12">
                        <p className="fs-4">Friends, here's all you need to know about promoting the game. We've made it easier with images and clear steps.</p>
                        <ul className="fs-5">
                            <li>
                                Share game links across diverse <b>social media platforms.</b>
                            </li>
                            <li>Guide your friends <b>face-to-face.</b></li>
                            <li>Enhance <b>content attractiveness</b> for better engagement.</li>
                            <li><b>Upload videos</b> and keep updating.</li>
                            <li>Master the power of <b>Google Ads.</b></li>
                            <li>Develop your <b>affiliate team.</b></li>
                        </ul>
                    </div>
                </div>
                <p className="fs-5 mt-4">For promoting Master <b>Teen Patti APK</b>, you need a comprehensive approach to reach and engage your target audience effectively. Here's a detailed and straightforward guide with actionable steps:</p>
                <ol>
                    <li className="fs-4 fw-bold">Share Game Links Across Diverse Social Media Platforms.</li>
                    <p className="fs-5 m-0 p-0">1. Post game links on different social media sites to reach more people.</p>
                    <p className="fs-5 m-0 p-0">2. Use various platforms to attract more players.</p>

                    <li className="fs-4 fw-bold mt-4">Guide Your Friends Face-to-Face.</li>
                    <p className="fs-5 m-0 p-0">1. Invite and explain the game to your friends in person.</p>
                    <p className="fs-5 m-0 p-0">2. Help them start playing by showing them how it works.</p>

                    <li className="fw-bold fs-4 mt-4">Enhance Content Attractiveness for Better Engagement.</li>
                    <p className="fs-5 m-0 p-0">1. Make your posts and images more appealing to grab attention.</p>
                    <p className="fs-5 m-0 p-0">2. Create interesting content to get more people to interact with it.</p>

                    <li className="fw-bold fs-4 mt-4">Upload Videos and Keep Updating.</li>
                    <p className="fs-5 m-0 p-0">1. Regularly post new videos about the game to keep things fresh.</p>
                    <p className="fs-5 m-0 p-0">2. Update your content often to keep players interested.</p>

                    <li className="fw-bold fs-4 mt-4">Master the Power of Google Ads.</li>
                    <p className="fs-5 m-0 p-0">1. Use Google Ads to promote your game and reach the right people.</p>
                    <p className="fs-5 m-0 p-0">2. Optimize your ads to attract more players.</p>

                    <li className="fw-bold fs-4 mt-4">Develop Your Affiliate Team.</li>
                    <p className="fs-5 m-0 p-0">1. Build a team to help promote the game and reach more people.</p>
                    <p className="fs-5 m-0 p-0">2. Give them what they need to successfully market the game.</p>
                </ol>
            </section>


        </>
    )
}