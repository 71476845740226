import React from "react";
import BlogsData from "./BlogsData.json"
import { Link } from "react-router-dom";

export const Blog = () => {
    return (
        <>
            <section className="container mt-5">
                <div className="row mb-5">
                    {BlogsData.map((Item) =>
                        <>
                            <div className="col-sm-12 col-md-12 mt-5">
                                <h2>{Item.title}</h2>
                                <p className="fs-5">{Item.name}</p>
                                <Link className="fs-5 fw-bold" style={{ color: "brown" }} to={Item.button}>Read More</Link>
                            </div>
                            <hr />

                        </>
                    )}
                </div>

            </section>

        </>
    )
}