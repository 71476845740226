import React from "react"
import BlogsData from "./BlogsData.json"
import { Link } from "react-router-dom";
import { Slick_Slider } from "./slick";

export const Home = () => {
    const filteredData = BlogsData.filter(item => item.id === 1 || item.id === 2);

    return (
        <>
            <section className="main_div" id="carousel">
                <div className="container-fluid p-0">
                    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src="asset/img/teenpatti.jpg" className="d-block img-fluid h_500 float-end" alt="..." />
                                <div className="carousel-caption d-none d-md-block carousel-caption-left left_pd">
                                    <Link to="https://earntp.com/m/27t0g8" className="fw-bold fs-2"><img src="asset/img/button1.gif" className="img-fluid" style={{ width: 400 }} alt="..." /></Link>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src="asset/img/teenpatti3.png" className="d-block img-fluid img-center h_500" alt="..." />
                                <div className="carousel-caption d-none d-md-block carousel-caption-left">
                                    <Link to="https://earntp.com/m/27t0g8" className=""><img src="asset/img/button1.gif" className="img-fluid" style={{ width: 400 }} alt="..." /></Link>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </section>

            <Link to="https://earntp.com/m/27t0g8" className=""><div className="fd position-fixed top-50 translate-middle-y"><span className="download-btn"><img className="fd-icon lazy-loading mobile_logo" width="200" height="327" src="/asset/img/sticker.png" alt="Teen Patti Master DOWNLOAD APP" /></span>
            </div></Link>

            <Link to="https://earntp.com/m/27t0g8" className=""><div className="fix_button position-fixed top-100"><span className="download-btn"><img className="fd-icon lazy-loading" width="200" height="100" src="/asset/img/button1.gif" alt="Teen Patti Master DOWNLOAD APP" /></span>
            </div></Link>

            <section className="pt-5 pb-5" style={{ backgroundColor: "#cccccc" }}>
                <div className="container">
                    <p className="fs-5 fw-bold m-0 p-0">Hello dear friend…,</p>
                    <p className="fs-5 m-0 p-0">do you want an easy way to earn money while playing games? Install the <b>Teen Patti Master</b> game app now and start earning  money fast!</p>
                    <p className="fs-5 mt-3">Teen Patti is very popular in India and around the world, giving you a great chance to win over 10,000 rupees from home. With mobile gaming becoming more common, it's now easier than ever to play Teen Patti using the <b>3 Patti Master Game App</b>. You can easily win up to 50,000+ rupees every month by playing! Download the Teen Patti Master App now!</p>
                    <p className="fs-5 mt-3">Teen Patti Master is a fresh take on the classic card game from India. You can play live with real players from all over the world, in the language you choose, anytime and anywhere. you'll find many cool features like Daily Bonus, Spin Wheel, Monthly Card, VIP Bonus, Gullak Bonus and Scratch Card to make money in this teen patti master.</p>
                    <p className="fs-5 text-center mt-4"><b>"Download the Teen Patti Master app now and begin earning real money!"</b></p>
                </div>
            </section>

            <section className="container-fluid second_div mb-5 mt-5" id="games">
                <div className="container text-center">
                    <p className="fw-bold fs-3 text-center underline_black">Most Popular Games</p>
                    <p className="fs-5 text-center">"Check out Teen Patti Master, a top online gaming app in India with over <b>30+ card and game options</b>. Enjoy our selection of games and experience something truly special!"</p>
                    <div className="">
                        <img src="asset/img/g1.jpg" className="imgstyle" alt="..." />
                        <img src="asset/img/g2.jpg" className="imgstyle" alt="..." />
                        <img src="asset/img/g13.jpg" className="imgstyle" alt="..." />
                        <img src="asset/img/g4.jpg" className="imgstyle" alt="..." />
                        <img src="asset/img/g5.jpg" className="imgstyle" alt="..." />
                        <img src="asset/img/g6.jpg" className="imgstyle" alt="..." />
                        <img src="asset/img/g7.jpg" className="imgstyle" alt="..." />
                        <img src="asset/img/g8.jpg" className="imgstyle" alt="..." />
                        <img src="asset/img/g9.jpg" className="imgstyle" alt="..." />
                        <img src="asset/img/g10.jpg" className="imgstyle" alt="..." />
                        <img src="asset/img/g11.jpg" className="imgstyle" alt="..." />
                        <img src="asset/img/g14.jpg" className="imgstyle" alt="..." />
                    </div>
                    <p className="mt-5 fs-5"><b>Start playing today and explore all these exciting options with your friends!</b></p>
                </div>
            </section>

            <section className="container-fulid pt-5 pb-5" id="install" style={{ backgroundColor: "#cccccc" }}>
                <div className="container">
                    <p className="fw-bold fs-3 mb-4 pb-2 text-center text-black underline_black">How To Install</p>
                    <p className="text-center fs-5 mb-5">Teen Patti Master is finally here! Download the APK on your Android and enjoy a top-notch gaming experience.</p>
                    <Slick_Slider className="" />
                </div>
            </section>

            <section className="bg-black pb-5 pt-3" id="withdraw">
                <div className="text-center">
                    <p className="fw-bold fs-3 pt-4 pb-2 mb-4 text-center text-white underline_white">Our Payment Partners /Our Withdrawal Partners</p>
                    <img src="asset/img/phonepe.jpg" className="" style={{ width: 150 }} alt="..." />
                    <img src="asset/img/gpay1.jpg" className="" style={{ width: 250 }} alt="..." />
                    <img src="asset/img/paytm.jpg" className="" style={{ width: 150 }} alt="..." />
                    <p className="text-white fs-5 mt-4">Easily transfer your game earnings to your bank account within <b>1 minute</b> with <br /> our seamless process.</p>
                </div>
            </section>

            <section className="mt-4" id="bonus">
                <div className="container">
                    <p className="fw-bold fs-3 pt-4 pb-2 mb-4 text-center underline_black">Available Cash Bonus/ Bonus commission</p>
                    <p className="fs-5 mt-4 pe-5">Hey guys! When you sign up on the app, you'll receive <b>multiple offers</b> to add cash. The more you add, the higher your commission, with over <b>30% available</b> for every transaction.</p>
                    <p className="fs-5 mt-4">Maximize your earnings with our exclusive bonus offers when you add funds to your account.</p>
                    <div className="row mt-5 mb-5">
                        <div className="col-md-5 col-sm-12 text-center">
                            <img src="asset/img/bonus5.jpg" className="img-thumbnail" style={{ width: 250, height: 350 }} alt="..." />
                        </div>
                        <div className="col-md-7 col-sm-12">
                            <ul className="fs-4 mt-4">
                                <li>Add <b>Rs.100</b> and receive a <b>15%</b> extra bonus</li>
                                <li>Add <b></b><b>Rs.300</b> and receive a <b>15%</b> extra bonus</li>
                                <li>Add <b>Rs.500</b> and receive a <b>20%</b> extra bonus</li>
                                <li>Add <b>Rs.1,000</b> and receive a <b>20%</b> extra bonus</li>
                                <li>Add <b>Rs.3,000</b> and receive a <b>25%</b> extra bonus</li>
                                <li>Add <b>Rs.5,000</b> and receive a <b>30%</b> extra bonus</li>
                                <li>Add <b>Rs.10,000</b> and receive a <b>30%</b> extra bonus</li>
                                <li>Add <b>Rs.20,000</b> and receive a <b>30%</b> extra bonus</li>
                            </ul>
                        </div>
                    </div>
                    <p className="fs-5 mt-4">Don't miss out on an additional <b>5% bonus</b> when you add funds using Paytm UPI. Start earning more with Teen Patti Master today!</p>
                </div>
            </section>

            <section className="container-fulid pt-5 pb-4 mt-5" id="Features" style={{ backgroundColor: "#cccccc" }}>
                <div className="container">
                    <p className="fw-bold fs-3 text-center underline_black">Amazing Features in Teen Patti Master</p>
                    <div className="row mt-5">
                        <div className="col-md-5 col-sm-12">
                            <img src="asset/img/jackpot.jpg" className="img-thumbnail" style={{ width: 400, height: 350 }} alt="..." />
                        </div>
                        <div className="col-md-7 col-sm-12">
                            <ul className="fs-4">
                                <li>You can play this game in <b>10+ languages</b> like Gujarati, English, and Hindi.</li>
                                <li>Daily Rewards and Bonuses.</li>
                                <li>High commission.</li>
                                <li>Multiple Game Modes.</li>
                                <li>Secure and Fair Gameplay.</li>
                                <li>VIP Gift.</li>
                                <li>Free Cash</li>
                                <li>24/7 Customer Support.</li>
                            </ul>
                        </div>
                        <p className="fs-4 fw-bold mt-5 text-center"> Start playing now and explore all this exciting features with your friends!</p>
                    </div>
                </div>
            </section>

            <section className="container mt-5" id="Blog">
                <div>
                    <p className="fw-bold fs-3 text-center underline_black">Blogs</p>
                    <div className="row mb-5">
                        {BlogsData.map((Item) =>
                            <>
                                <div className="row mt-5">
                                    <h3>{Item.title}</h3>
                                    <p className="fs-5">{Item.name}</p>
                                    <Link className="fs-5 fw-bold" style={{ color: "brown" }} to={Item.button}>Read More</Link>
                                </div>
                                <hr />

                            </>
                        )}
                    </div>
                </div>
            </section>

            <section className="bg-black pb-5 pt-5" id="withdraw">
                <div className="container stext-center">
                    <p className="text-danger">Disclaimer: This game involves an element of financial risk and may be addictive. Please play responsibly and at your won risk.This game is strictly for users 18+.</p>

                    <p className="text-warning">Warning: teenpattimasterclassic.in provides direct download links for Teen Patti Master, We don't own the Teen patti Master app or its copyrights; this site is for Teen Patti Master APK download only.
                    </p>
                </div>
            </section>

        </>
    )
}