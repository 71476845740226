import React from "react"

export const Header = () => {
    return (
        <>

            <section className="laptop_nav">
                <nav className="navbar navbar-expand-lg bg-dark" data-bs-theme="dark">
                    <div className="container">
                        <a className="navbar-brand" href="/"><img src="asset/img/logo2.jpg" className="" style={{ width: 60 }} alt="..." /></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end:" id="navbarText">
                            <ul className="navbar-nav fs-5 mb-2 ms-auto mb-lg-1">
                                <li className="nav-item me-3">
                                    <a className="nav-link" aria-current="page" href="/">Home</a>
                                </li>
                                <li className="nav-item me-3">
                                    <a className="nav-link" aria-current="page" href="/game">Games</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link me-3" href="/feature">Features</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link me-3" href="/about">About Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link me-3" href="/faq">FAQs</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link me-3" href="/blog">Blogs</a>
                                </li>

                                <li className="nav-item">
                                    <a className="nav-link" href="https://earntp.com/m/27t0g8?f=w&p=wa&l=en&tp=m96"><button type="button" className="btn btn-light">Download App</button></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </section>

            <section className="navbar p-0 mobile_nav">
                <ul className="nav mobile_nav_div bg-dark p-0 m-0 pt-2 pb-2">
                    <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="/">Home</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/game">Games</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/feature">Feature</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/about">About Us</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/faq">FAQs</a>
                    </li>
                </ul>
            </section>
        </>
    )
}
