import React from "react";
import GroupsIcon from '@mui/icons-material/Groups';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SportsEsportsRoundedIcon from '@mui/icons-material/SportsEsportsRounded';
import SavingsIcon from '@mui/icons-material/Savings';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';


export const About = () => {
    return (
        <>
            <section>
                <div className="container mt-5">
                    <p className="fw-bold fs-3 text-center underline_black">About Us</p>
                </div>
                <div className="container mt-4">
                    <p className="fs-5">Hey friends! Want an easy way to make cash playing games? Install the <b>Teen Patti Master</b> app now and start earning money fast. Teen Patti is super popular in India and worldwide, offering a chance to win over 10,000 rupees from home. With the Teen Patti Master Game App, playing this classic card game has never been easier. Play Teen Patti Master and <b>win over 10,000 rupees</b> every month! Download now! Teen Patti Master is a new version of the classic Indian card game. </p>

                    <p className="fs-5">Discover the <b>Teen Patti Master</b> app and start earning today! Simply install the app, invite your friends to join, and earn <b>up to Rs.5100</b> for each friend who signs up. It's a fun and rewarding way to enjoy your favorite card game while making money. Don't miss out on this exciting opportunity!</p>

                    <p className="fs-5">Play live with real players worldwide, in your language, anytime, anywhere. <b>Play with friends</b> or join public games. The app offers various game modes like classic, turbo, and private tournaments. Whether you're a newbie or a pro, the 3 Patti Master App guarantees fun, excitement, and rewards.</p>
                    <p className="fs-4 fw-bold text-center">Start playing "Teen Patti Master" today and enjoy winning!</p>

                </div>
                <div className="container mt-5 mb-5">
                    <p className="fs-4 fw-bold">Teen Patti Master App Details:</p>
                    <table className="table table-bordered table_w">
                        <thead>
                            <tr>
                                <th scope="col">Attribute</th>
                                <th scope="col">Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>App Name:</td>
                                <td>Teen Patti Masters</td>
                            </tr>
                            <tr>
                                <td>Publisher:</td>
                                <td>Teen Patti Masters</td>
                            </tr>
                            <tr>
                                <td>App Size:</td>
                                <td>72.43 MB</td>
                            </tr>
                            <tr>
                                <td>App Version:</td>
                                <td>Teen Patti Master 2024</td>
                            </tr>
                            <tr>
                                <td>Sign Up Bonus:</td>
                                <td> Upto ₹1200</td>
                            </tr>
                            <tr>
                                <td>Refer Code:</td>
                                <td>27t0g8</td>
                            </tr>
                            <tr>
                                <td>Refer and Earn:</td>
                                <td>Earn ₹51 when you sign up and 30% commission for life</td>
                            </tr>
                            <tr>
                                <td>APK Update:</td>
                                <td>Yesterday</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
            <section className="container mb-5">
                <p className="text-center fs-4 fw-bold underline_black mb-5">Why install the Teen Patti Master app?</p>
                <div className="row mt-4 ms-5">
                    <div className="col-md-4 col-sm-12 mb-3">
                        <div className="card border-4" style={{ width: "80%", backgroundColor: 'rgb(232 228 228)' }}>
                            <div className="card-body text-center">
                                <GroupsIcon style={{ fontSize: 80 }} />
                                {/* <h5 className="card-title"><GroupsIcon /></h5> */}
                                <p className="card-text fs-5 pb-4">Join <b>Millions Playing</b> Anytime, Anywhere.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 mb-3">
                        <div className="card border-4" style={{ width: "80%", backgroundColor: 'rgb(232 228 228)' }}>
                            <div className="card-body text-center">
                                <VerifiedUserIcon style={{ fontSize: 80 }} />
                                <p className="card-text fs-5 pb-4">Completely <b>Legal and Secure</b> with Official Guarantee.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 mb-3">
                        <div className="card border-4" style={{ width: "80%", backgroundColor: 'rgb(232 228 228)' }}>
                            <div className="card-body text-center">
                                <SportsEsportsRoundedIcon style={{ fontSize: 80 }} />
                                <p className="card-text fs-5 pb-4">Access Over <b>25 Games</b> in One App.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4 ms-5">
                    <div className="col-md-4 col-sm-12 mb-3">
                        <div className="card border-4" style={{ width: "80%", backgroundColor: 'rgb(232 228 228)' }}>
                            <div className="card-body text-center">
                                <SavingsIcon style={{ fontSize: 80 }} />
                                {/* <h5 className="card-title"><GroupsIcon /></h5> */}
                                <p className="card-text fs-5 pb-4">Instant Payouts: Get Your Winnings in <b>1 Minute.</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12 mb-3">
                        <div className="card border-4" style={{ width: "80%", backgroundColor: 'rgb(232 228 228)' }}>
                            <div className="card-body text-center">
                                <SupportAgentIcon style={{ fontSize: 80 }} />
                                <p className="card-text fs-5 pb-4"><b>24/7 Customer Support</b> via Chat and Phone.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div className="card border-4" style={{ width: "80%", backgroundColor: 'rgb(232 228 228)' }}>
                            <div className="card-body text-center">
                                <AttachMoneyOutlinedIcon style={{ fontSize: 80 }} />
                                <p className="card-text fs-5 pb-4"><b>Risk-Free</b> Accounts and <b>Secure Cash</b> Protection.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5" id="3-step">
                <div className="container">
                    <p className="fw-bold fs-3 text-center underline_black">How to Make Real Money with Teen Patti Master: A Simple 3-Step Guide</p>
                    <p className="fs-5 mt-5">Want to turn your gaming time into real cash? It’s easy with Teen Patti Master! Follow these three simple steps to get started:</p>
                    <ul>
                        <li className="fs-4 fw-bold">Download the App</li>
                        <p className="fs-5">Click the download button above to install the Teen Patti Master App. Join the game and start having fun right away!</p>
                        <li className="fs-4 fw-bold">Create Your Account</li>
                        <p className="fs-5">Sign up quickly by entering your mobile number and the OTP you receive. Setting up your Teen Patti Master account is fast and easy.</p>
                        <li className="fs-4 fw-bold">Start Earning Cash</li>
                        <p className="fs-5">Once you’re logged in, you can start earning real money by referring friends to the game. Discover how you can benefit from your referrals while enjoying the exciting gameplay!</p>
                    </ul>
                </div>
            </section>
        </>
    )
}