import React from "react";
import { Link } from "react-router-dom"
import GameData from "./GameData.json"

export const Games = () => {
    return (
        <>
            <section className="container">
                <p className="fw-bold fs-3 pt-4 pb-2 mb-2 text-center underline">All Games</p>
                <p className="fs-5 text-center">This APK offers real cash games, so it's important to have all the details on how you can earn money with these games..</p>
                <div className="row mt-5 ms-5">
                    {GameData.map((Item) =>
                        <>
                            <div className="col-md-4 col-sm-12">
                                <div className="card card_style" style={{ height: "95%" }}>
                                    <img src={Item.img} className="card-img-top card_img_style" alt="..." />
                                    <div className="card-body">
                                        <p className="card-text"><b className="fs-5">Description:</b> {Item.description}</p>
                                        {/* <p style={{ whiteSpace: 'pre-line' }}><b className="fs-5">How To Play:</b> {Item.gameplay}</p> */}
                                        <div className="d-flex justify-content-center">
                                            <Link to="https://earntp.com/m/27t0g8" className=""><img src="asset/img/button.gif" className="" style={{ width: 200 }} alt="..." /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>)}
                </div>
            </section>
        </>
    )
}