import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Header } from "./custom_components/Header"
import { Home } from "./pages/Home"
import './App.css';
import { Blog } from './pages/Blogs';
import { Games } from './pages/Game';
import { FAQs } from './pages/Faqs';
import { Feature } from './pages/Features';
import { About } from './pages/AboutUs';
import { Blog1 } from './pages/Blogs/Blog1';
import { Blog2 } from './pages/Blogs/Blog2';
import { Blog3 } from './pages/Blogs/Blog3';


export const App = () => {
    return (
        <>
            <slick_slider />
            <Header />
            <Routes>
                <Route element={<Home />} path='/' />
                <Route element={<Blog />} path='/blog' />
                <Route element={<Blog1 />} path='/blog1' />
                <Route element={<Blog2 />} path='/blog2' />
                <Route element={<Blog3 />} path='/blog3' />
                {/* <Route element={<Bloginfo />} path='/bloginfo' /> */}
                <Route element={<Games />} path='/game' />
                <Route element={<FAQs />} path='/faq' />
                <Route element={<Feature />} path='/feature' />
                <Route element={<About />} path='/about' />
            </Routes>
        </>
    )
}